<template>
    <div>
        <v-simple-table class="v-data-table--line v-data-table--no-hover w-100">
            <tbody>
                <tr>
                    <th>
                        <v-text-field v-model="board.subject" v-bind="{ ...attrs_input, loading }" placeholder="제목을 입력해주세요" @input="$emit('input', board)" />
                    </th>
                </tr>
                <tr>
                    <td>
                        <naver-smarteditor v-model="board.content" @input="$emit('input', board)" />
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="btn-wrap">
            <v-row class="row--xs">
                <v-col cols="auto">
                    <v-btn outlined color="grey" @click="$router.go(-1)">취소</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="grey darken-4" @click="$emit('save')">확인</v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- <div class="board-input">
            <div class="board-input__contents">
                <div class="py-12">
                    <v-row no-gutters align="center">
                        <v-col cols="12" md="2" class="board-input__row-header"><span>Title</span></v-col>
                        <v-col cols="12" md="10">
                            <v-text-field v-model="board.subject" @input="$emit('input', board)" outlined hide-details />
                        </v-col>
                    </v-row>
                </div>
                <v-divider />

                <div class="py-12">
                    <naver-smarteditor v-model="board.content" @input="$emit('input', board)" />
                </div>
            </div>

            <div class="board-bottom">
                <v-row justify="end" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn small exact color="grey-6" class="w-100px" @click="$router.go(-1)">Cancel</v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn small exact color="secondary" class="w-100px" @click="$emit('save')">Confirm</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div> -->
    </div>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
const categories = [];

export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            attrs_input,
            categories,
            board: this.$props.value,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
};
</script>
