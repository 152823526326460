var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-simple-table', {
    staticClass: "v-data-table--line v-data-table--no-hover w-100"
  }, [_c('tbody', [_c('tr', [_c('th', [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "제목을 입력해주세요"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)]), _c('tr', [_c('td', [_c('naver-smarteditor', {
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.board);
      }
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)])])]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("취소")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "grey darken-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }